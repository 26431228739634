<template>
  <div class="terms-conditions">
    <h2>Terms of use</h2>

    <p>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS RELATING TO YOUR USE OF OUR APPLICATION CAREFULLY. By using our Application, you agree to these Terms and Conditions of Use ("Terms & Conditions"). We reserve the right, in our sole discretion, to change, modify, add or remove portions of these Terms & Conditions at any time. You should check these Terms & Conditions periodically for changes. By using this Application after we post any changes to these Terms & Conditions, you agree to accept those changes, whether or not you have reviewed them. If you do not agree to these Terms & Conditions, you should not use our Application and, if applicable, you should arrange to cancel your user account(s).</p>
  </div>
</template>

<script>
export default {
  name: 'TermsConditionsPage',
}
</script>
